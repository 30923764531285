<template>
  <div class="flex h-full flex-col">
    <header
      class="fixed inset-x-0 top-0 z-10 flex h-14 w-full items-center bg-white px-2 shadow-sm"
    >
      <slot name="header" />
    </header>
    <main class="flex w-full grow flex-col overflow-y-auto pt-14">
      <slot />
    </main>
  </div>
</template>
